<template>
	<div class="animated fadeIn">
		<b-card class="pb-5">
			<div class="display-table full-width mb-2">
				<strong class="card-title">{{$route.meta.label}}</strong>
			</div>
			<b-row>
        <ResponseAlert ref="response" />
        <b-col sm="2" class="mb-2">
          <div class="image-input">
            <b-img :src="getUrlOfFile(models.image_profile)" class="mb-2" />
          </div>
        </b-col>
        <b-col sm="10" class="mb-2">
          <b-row>
            <b-col sm="6">
              <b-form-group label="Full Name">
                <b-form-input disabled v-model="fullname">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Email">
                <b-form-input disabled v-model="models.email">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="contributor" >
              <b-form-group label="Pen-name">
                <b-form-input disabled v-model="pena_name">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="contributor">
              <b-form-group label="Date of Birth">
                <b-form-input disabled v-model="models.dob">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Registered At">
                <b-form-input disabled v-model="models.registered_at">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Gender">
                <b-form-input disabled v-model="models.gender">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Status">
                <b-form-input v-if="contributor" disabled v-model="models.contributor_status">
                </b-form-input>
                <b-form-input v-else-if="membership" disabled v-model="models.membership_status">
                </b-form-input>
                <b-form-input v-else disabled v-model="models.status">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="contributor">
            <b-col v-if="models.ktp" sm="6">
              <b-form-group label="Foto KTP">
                <img class="w-100" :src="models.ktp" alt="foto_ktp">
              </b-form-group>
            </b-col>
            <b-col v-if="models.selfie_ktp" sm="6">
              <b-form-group label="Foto Selfie KTP">
                <img class="w-100" :src="models.selfie_ktp" alt="selfie_ktp">
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="membership">
        <h5 class="font-weight-bold">Transaction History</h5>
        <div class="my-4">
          <b-row>
            <b-col sm="12">
              <div class="table-responsive">
                <table class="table b-table">
                  <thead>
                    <tr>
                      <th v-for="(row, key) in config.headers" :width="row.width" :key="key"
                        :class="row.class">
                        <a v-if="row.value" class="sort dark-font" v-on:click="sort(row.value)"
                          href="javascript:void(0)">
                          {{row.title}}
                          <i :id="row.value" class="fa fa-sort"></i>
                        </a>
                        <a v-else class="sort dark-font" :id="row.value" href="javascript:void(0)">
                          {{row.title}}
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="config.total_data">
                      <tr v-for="(row, key) in config.rows" :key="key">
                        <td>{{row.transaction_number}}</td>
                        <td>{{row.package && row.package.name}}</td>
                        <td>{{row.package && row.package.period}}</td>
                        <td>{{row.payment_method}}</td>
                        <td>{{row.total}}</td>
                        <td>{{row.status}}</td>
                      </tr>
                    </template>
                    <tr v-if="!config.total_data">
                      <td :colspan="config.headers.length" align="center"> No data available. </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="config.total_data">
                    <tr>
                      <td :colspan="config.headers.length" class="no-padding">
                        <div class="pull-left table-information mt-25">
                          <p>Showing {{config.page}} to {{config.total_page}} of {{config.total_data}}
                            entries</p>
                        </div>
                        <div class="pull-right pagination mt-25">
                          <b-pagination @change="gotoPage" :total-rows="config.total_data"
                            v-model="config.page" :per-page="config.per_page">
                          </b-pagination>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </b-col>
          </b-row>
          <hr>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-center">
          <b-button type="button" variant="light" class="btn-min-width rounded float-left px-5" @click="$router.push({name: config.uri})">
            Cancel
          </b-button>
          <b-button v-if="(!contributor && membershipActive) || user" type="button" @click.prevent="destroy" variant="danger" class="btn-min-width rounded float-left px-5">
            {{membership ? 'Delete Membership' : "Delete"}}
          </b-button>
          <b-button v-if="contributor && models.contributor_status !== 'waiting approval' && models.contributor_status !== 'reject'" type="button" @click.prevent="changeStatus(models.contributor_status)" :variant="statusActionBg" class="btn-min-width rounded float-left px-5">
            {{statusAction}}
          </b-button>
          <b-button v-if="!hideInactivated" type="button" @click.prevent="changeStatus(models.status)" :variant="statusActionUserBg" class="btn-min-width rounded float-left px-5">
            {{statusActionUser}}
          </b-button>
        </div>
      </template>
		</b-card>
	</div>
</template>
<script>
  import moment from 'moment'
	import api from '@/utils/api.js'
	import table from '@/utils/table.js'
	import ResponseAlert from '@/components/response_alert';
  import {
		getUrlOfFile
	} from '@/utils/helpers.js';

	export default {
		name: 'DetailUser',
    components: {
      ResponseAlert
    },
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
					api: api.transaction,
					rows: [
					],
					total_data: 0,
					total_page: 0,
					per_page: 10,
					page: 1,
					orderby: "id",
					sort: "desc",
					additional_params: {
            user_id: ''
					},
					search: {
						key: ""
					},
					headers: [{
						title: "Transaction Number",
						value: false,
						align: "text-left",
						width: "20%",
					}, {
						title: "Package",
						value: false,
						align: "text-left",
						width: "15%",
					},{
						title: "Period",
						value: false,
						align: "text-left",
						width: "15%",
					}, {
						title: "Payment Method",
						value: false,
						align: "text-left",
						width: "20%",
					}, {
						title: "Total Payment",
						value: false,
						align: "text-left",
						width: "15%",
					},{
						title: "Status",
						value: false,
						align: "text-left",
						width: "15%",
					}],
				},
				models: {
          image_profile: '',
          first_name: '',
          last_name: '',
          email: '',
          registered_at: '',
          dob: "",
          gender: '',
          phone_number: '',
          contributor_status: '',
          status: '',
          ktp: null,
          selfie_ktp: null,
          membership_status: ''
        },
        pena_name: ""
			}
		},
		created() {
			const _ = this;
			_.getById();
      _.getTransactionHistory()
		},
		methods: {
      getUrlOfFile(file) {
				return getUrlOfFile(file);
			},
      getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.user}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.first_name = data.first_name;
							_.models.last_name = data.last_name;
							_.models.email = data.email;
              _.models.registered_at = data.contributor_registered_at ? moment(data.contributor_registered_at).format('DD/MM/YYYY') : moment(data.created_at).format('DD/MM/YYYY')
              _.models.dob = data.dob ? moment(data.dob).format('DD/MM/YYYY') : ''
              _.models.gender = data.gender
              _.models.phone_number = data.phone_number
							_.models.status = data.status;
							_.models.contributor_status = data.contributor_status;
							_.models.image_profile = data.image_profile;

              // Membership
							_.models.membership_status = data.membership_status;

              // contributor
							_.models.ktp = data.ktp;
							_.models.selfie_ktp = data.selfie_ktp;

              if(data.role.id === 1) {
                this.pena_name = data.pena_name
              }
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},

      getTransactionHistory() {
        if(this.membership) {
          this.config.additional_params.user_id = this.$route.params.id
          table.get(this.config)
        }
      },

      sort(orderby) {
				table.sort(this.config, orderby)
			},
			gotoPage(page) {
				table.gotoPage(this.config, page)
			},

      changeStatus(status) {
        const _ = this
        const statusText = status == 'active' ? 'inactive' : 'active'

        let typeStatus = 'status'
        let data = {}

        if(this.contributor) {
          typeStatus = 'contributor-status'
          data.contributor_status = statusText
        } else {
          data.status = statusText
        }

				_.$axios.put(`${api.user}/${typeStatus}/${_.$route.params.id}`, data)
          .then(res => {
            _.$refs.response.setResponse(res.data.message, 'success');
            _.getById()
          })
          .catch(error => {
            _.$refs.response.setResponse(error.response.data.message, 'danger')
          })
      },

			destroy() {
				const _ = this;
				_.$dialog.confirm('Are you sure want to delete this data?', {
						"okText": "Yes",
						"cancelText": "No"
					})
					.then(() => {
            let request = ''
            if(this.membership) {
              request = _.$axios.put(`${api.user}/membership_status/${_.$route.params.id}`, {membership_status: 'inactive'})
            } else {
              request = _.$axios.delete(`${api.user}/${_.$route.params.id}`)
            }
            request
              .then(() => {
                let message = 'delete'
                if(this.membership) {
                  message = 'delete membership'
                }
                this.$showToast('Success', `Success ${message} user`)
                _.$router.push({
                  name: _.config.uri
                })
              }).catch(error => {
                _.$refs.response.setResponse(error.response.data.message, 'danger')	
              })
					})
			},
		},
    computed: {
      fullname() {
        return `${this.models.first_name} ${this.models.last_name}`
      },
      statusAction(){
        return this.models.contributor_status == 'active' ? 'Inactivated' : 'Activated'
      },
      statusActionUser(){
        return this.models.status == 'active' ? 'Inactivated' : 'Activated'
      },
      statusActionBg(){
        return this.models.contributor_status == 'active' ? 'warning' : 'success'
      },
      statusActionUserBg(){
        return this.models.status == 'active' ? 'warning' : 'success'
      },
      contributor() {
        return this.$route.name === 'detailcontributor'
      },
      membership(){
        return this.$route.name === 'detailmembership'
      },
      user() {
        return this.$route.name === 'detailuser'
      },
      membershipActive() {
        return this.models.membership_status === 'active'
      },
      hideInactivated() {
        return (this.config.uri === 'membership' && this.models.status === 'active') || this.contributor
      }
    }
	}
</script>